<template>
    <div class="wrap">
        <!-- 1填写个人资料或2绑定手机号或3填写企业用户资料 -->
        <b-container class="commodity-group">
            <div class="login-group-tab">
                <span v-show="showFlag=='1'||showFlag=='3'">{{$t('message.addinfo')}}</span>
                <span v-show="showFlag=='2'">{{$t('message.bindphone')}}</span>
            </div>
            <!-- showFlag：3企业完善信息 -->
            <!-- <div class="login-group" v-show="showFlag=='3'"><input type="text" v-model="companyObj.companyname" class="password" :placeholder="$t('message.companyname')"/></div> -->
            <div class="login-group" v-show="showFlag=='3'"><input type="text" v-model="companyObj.company_name" class="password" :placeholder="$t('message.companytitle')"/></div>
            <div class="login-group" v-show="showFlag=='3'"><input type="text" v-model="companyObj.ein" class="password" :placeholder="$t('message.companynum')"/></div>
            <div class="login-group" v-show="showFlag=='3'"><input type="text" v-model="companyObj.address" class="password" :placeholder="$t('message.companyaddress')"/></div>
            <div class="login-group" v-show="showFlag=='3'"><input type="text" v-model="companyObj.mobile" class="password" :placeholder="$t('message.companymobile')"/></div>
            <div class="login-group" v-show="showFlag=='3'"><input type="text" v-model="companyObj.electronic_invoice_number" class="password" :placeholder="$t('message.companyenum')"/></div>
            <div class="login-group" v-show="showFlag=='3'"><input type="text" v-model="companyObj.email" class="password" :placeholder="$t('message.email')"/></div>
            <!-- showFlag：1完善信息 -->
            <div class="login-group" v-show="showFlag=='1'"><input type="text" v-model="paramsDataObj.family_name" class="password" :placeholder="$t('message.surname')"/></div>
            <div class="login-group" v-show="showFlag=='1'"><input type="text" v-model="paramsDataObj.name" class="password" :placeholder="$t('message.name')"/></div>
            <div class="login-group" v-show="showFlag=='1'"><input type="text" v-model="paramsDataObj.nationality" class="password" :placeholder="$t('message.country')"/></div>
            <div class="login-group" v-show="showFlag=='1'"><input type="text" v-model="paramsDataObj.email" class="password" :placeholder="$t('message.email')"/></div>
            <!-- showFlag：2立即绑定 -->
            <div class="login-group" v-show="showFlag=='2'">
                <select name="" id="" v-model="couponSelected">
                    <option :value="item.mobile_area_code" v-for="(item,index) in languageList" :key="index">{{item.mobile_area_code}}+</option>
                </select>
                <input type="text" class="" v-model="phone" :placeholder="$t('message.phonenum')"/>
            </div>
            <div class="login-group-code" v-show="showFlag=='2'">
                <input type="text" class="" v-model="code" :placeholder="$t('message.ycode')"/>
                <div class="button" :class="dislebal?'glay':''">
                    <span v-show="dislebal">{{count}}s{{$t('message.coderest')}}</span>
                    <span v-show="!dislebal" @click="getCode">{{$t('message.getcode')}}</span>
                </div>
            </div>
            <div class="login-group" v-show="showFlag=='2'">
                <input type="password" v-model="password" class="password" :placeholder="$t('message.passnum')" />
            </div>
            <div class="login-group" v-show="showFlag=='2'">
                <input type="text" class="password" v-model="invitation" :placeholder="$t('message.invitationnum')"/>
            </div>
            <div class="login-group-btn" @click="getSubmit">{{showFlag=='2'?$t('message.nowbind'):$t('message.firm')}}</div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {jsReg,yooz_lang,web_inter,setLocalItem,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'uinfo',
    inject:['reload'],
    data() {
        return {
            ubid:'',
            token:'',
            languageName:'',
            languageObj:{},
            showFlag:'',//1：完善信息、2：立即绑定、3：企业完善信息
            paramsDataObj : {
                family_name:'',
                name:'',
                nationality:'',
                email:''
            },
            companyObj:{
                company_name:"",//企业名称
                ein:"",//税号
                address:"",//企业地址
                mobile:"",//联系电话
                electronic_invoice_number:"",//电子发票
                email:"",//邮箱
            },
            phone:'',
            timer: null,
            dislebal:false,
            count:0,
            code:'',
            password:'',
            invitation:'',
            languageList:web_inter.languageList,
            couponSelected:''
        }
    },
    watch:{
        searchParams:function() {
            // this.getTabFirstList();
        },
    },
    computed:{
        searchParams() {
            if(this.$route.query&&this.$route.query.type){
                var params = {
                    "id":this.$route.query.type
                };
                this.showFlag = params.id;
                return params;
            }
        },
    },
    methods: {
        getTabFirstList(){
            this.showFlag = this.searchParams.id;
        },
        // 完善信息或立即绑定
        getSubmit(){
            let routes = this.$route.query;//地址栏参数
            if(routes){
                if(routes.type){
                    this.showFlag = routes.type;
                }
                if(routes.ubid){
                    this.ubid = routes.ubid;
                }
            }
            if(this.showFlag == '1'){
                //  完善信息
                if(this.paramsDataObj.family_name==''){
                    ElementUI.Message({
                        message:this.languageObj.lasnametip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.paramsDataObj.name==''){
                    ElementUI.Message({
                        message:this.languageObj.nametip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.paramsDataObj.nationality==''){
                    ElementUI.Message({
                        message:this.languageObj.countrytip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.paramsDataObj.email==''){
                    ElementUI.Message({
                        message:this.languageObj.emailtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.email.test(this.paramsDataObj.email)){
                    ElementUI.Message({
                        message:this.languageObj.emailrighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    if(this.token){
                        let params = this.paramsDataObj;
                        params.token = this.token;
                        this.$http.api_user_infofrom(params).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.paramsDataObj = {};
                                        setLocalItem('login_user_info',res.data);
                                        this.$root.Bus.$emit('userInfoClick', res.data);
                                        this.$store.commit('setLoginNameStatus', res.data);
                                        this.reload();
                                        setTimeout(()=>{
                                            this.$router.go(-1);//返回上一页
                                        },1000);
                                    }
                                }else if(res.code==-1){
                                    // this.reload();
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.logintip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            }else if(this.showFlag == '2'){
                // 立即绑定
                if (this.phone=='') {
                    ElementUI.Message({
                        message:this.languageObj.phonetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.number.test(this.phone)){
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.code==''){
                    ElementUI.Message({
                        message:this.languageObj.phonecodetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.password==''){
                    ElementUI.Message({
                        message:this.languageObj.passwordtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    // 第三方登录后先绑定手机号后完善信息
                    let params = {
                        account:this.phone,
                        code:this.code,
                        password:this.password,
                        areacode:this.couponSelected,
                        token:this.token,// 	用户token 用于第三方用户绑定 为空则为用户注册
                        ubid:this.ubid,
                        invitation_code:this.invitation,//企业邀请码
                    }
                    if(this.ubid){
                        this.$http.api_phone_register(params).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.code = '';
                                        this.phone = '';
                                        this.password = '';
                                        setLocalItem('login_user_info',res.data);
                                        this.$root.Bus.$emit('userInfoClick', res.data);
                                        this.$store.commit('setLoginNameStatus', res.data);
                                        // this.reload();
                                        setTimeout(()=>{
                                            this.showFlag = '1';
                                            // 企业用户跳转商品列表页
                                            if(res.data.is_company==1){
                                                this.$router.push({path:'/list',query:{type:0}});
                                            }else{
                                                this.$router.push({path:'/'});
                                            }
                                        },1000);
                                        ElementUI.Message({
                                            message:res.message,
                                            type:'success',
                                            center: true,
                                            offset:10,
                                        });
                                        // 企业用户跳转商品列表页
                                        if(res.data.is_company==1){
                                            this.$router.push({path:'/list',query:{type:0}});
                                        }else{
                                            this.$router.push({path:'/'});
                                        }
                                    }
                                }else if(res.code==-1){
                                    // this.reload();
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }
            }else if(this.showFlag == '3'){
                //  企业完善信息
                // company_name（企业名称）、mobile（联系电话）、address（企业地址）、ein（税号）、electronic_invoice_number（电子发票号）
                if(this.companyObj.company_name==''){
                    ElementUI.Message({
                        message:this.languageObj.companytitle,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.companyObj.ein==''){
                    ElementUI.Message({
                        message:this.languageObj.companynum,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.companyObj.address==''){
                    ElementUI.Message({
                        message:this.languageObj.companyaddress,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.companyObj.mobile==''){
                    ElementUI.Message({
                        message:this.languageObj.companymobile,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.companyObj.electronic_invoice_number==''){
                    ElementUI.Message({
                        message:this.languageObj.companyenum,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.companyObj.email==''){
                    ElementUI.Message({
                        message:this.languageObj.emailtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.email.test(this.companyObj.email)){
                    ElementUI.Message({
                        message:this.languageObj.emailrighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    if(this.token){
                        let params = this.companyObj;
                        params.token = this.token;
                        this.$http.api_user_savebaseinfobytokenforcompany(params).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.companyObj = {};
                                        setLocalItem('login_user_info',res.data);
                                        this.$root.Bus.$emit('userInfoClick', res.data);
                                        this.$store.commit('setLoginNameStatus', res.data);
                                        this.reload();
                                        setTimeout(()=>{
                                            // this.$router.go(-1);//返回上一页
                                            this.$router.push({path:'/list',query:{type:0}});
                                        },1000);
                                    }
                                }else if(res.code==-1){
                                    // this.reload();
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.logintip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            }
        },
        // 获取验证码
        getCode(){
            let self = this;
            if(self.phone==''){
                ElementUI.Message({
                    message:this.languageObj.phonetip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(!jsReg.number.test(this.phone)){
                ElementUI.Message({
                    message:this.languageObj.phonerighttip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else{
                let params = {
                    telno:self.phone,//（手机号）、 
                    type:8,//（1短信注册 2短信忘记密码 8第三方绑定手机号）,
                    areacode:self.couponSelected,
                }
                this.$http.api_phone_sendcode(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            ElementUI.Message({
                                message:this.languageObj.codesuccess,
                                type:'success',
                                center: true,
                                offset:10,
                            });
                            self.dislebal = true;
                            const TIME_COUNT = 60;
                            if (!self.timer) {
                                self.count = TIME_COUNT;
                                self.timer = setInterval(() => {
                                    if (self.count > 0 && self.count <= TIME_COUNT) {
                                        self.count--;
                                    } else {
                                        clearInterval(self.timer);
                                        self.timer = null;
                                        self.dislebal = false;
                                    }
                                }, 1000);
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        },
    },
    mounted(){
        let that = this;
        that.$nextTick(()=>{
            that.couponSelected = that.languageList[0].mobile_area_code
        });
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "emailtip":"请输入您的邮箱",
                "passwordtip":"请输入您的密码",
                "phonetip":"请输入您的手机号",
                "phonecodetip":"请输入您的短信验证码",
                "countrytip":"请输入您的国家",
                "logintip":"请先登录",
                "nametip":"请输入您的名",
                "lasnametip":"请输入您的姓",
                "codesuccess":"验证码发送成功",
                "emailrighttip":"请输入正确的邮箱",
                "phonerighttip":"请输入正确的手机号",
                "companyname":"请输入企业名字",
                "companytitle":"请输入企业名称",
                "companynum":"请输入企业税号",
                "companyaddress":"请输入企业地址",
                "companymobile":"请输入联系电话",
                "companyenum":"请输入电子发票号码",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "emailtip":"enter your email",
                "passwordtip":"enter your password",
                "phonetip":"enter your mobile number",
                "phonecodetip":"enter your SMS verification code",
                "countrytip":"enter your country",
                "logintip":"please log in first",
                "nametip":"enter your name",
                "lasnametip":"enter your last name",
                "codesuccess":"Verification code sent successfully",
                "emailrighttip":"Please enter the correct email address",
                "phonerighttip":"Please enter the correct mobile number",
                "companyname":"Please enter the enterprise name",
                "companytitle":"Please enter the enterprise name",
                "companynum":"Please enter the enterprise tax number",
                "companyaddress":"Please enter the enterprise address",
                "companymobile":"Please enter the contact number",
                "companyenum":"Please enter the electronic invoice number",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "emailtip":"inserisci la tua email",
                "passwordtip":"inserisci la password",
                "phonetip":"inserisci il tuo numero di cellulare",
                "phonecodetip":"Inserisci il tuo codice di verifica SMS",
                "countrytip":"inserisci il tuo paese",
                "logintip":"fai prima il log in",
                "nametip":"inserisci il tuo nome",
                "lasnametip":"inserisci il tuo cognome",
                "codesuccess":"Codice di verifica inviato correttamente",
                "emailrighttip":"Inserisci l'indirizzo email corretto",
                "phonerighttip":"Inserisci il numero di cellulare corretto",
                "companyname":"Inserisci il nome dell'impresa",
                "companytitle":"Inserisci il nome dell'impresa",
                "companynum":"Inserire il numero di imposta sulle imprese",
                "companyaddress":"Inserisci l'indirizzo dell'azienda",
                "companymobile":"Inserisci il numero di contatto",
                "companyenum":"Inserire il numero di fattura elettronica",
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            let routes = this.$route.query;//地址栏参数
            if(routes){
                if(routes.type){
                    this.showFlag = routes.type;
                }
                if(routes.ubid){
                    this.ubid = routes.ubid;
                }
            }
        }
        // else{
        //     this.$router.push({path:'/'});
        //     return false;
        // }
    }
}
</script>
<style lang="scss" scoped>

.wrap{
    height:100%;
    width:100%;
}
.commodity-group{
    padding:30px 10px 50px;
}

.login-group-tab{
    padding:30px 0 10px;
    margin-bottom: 20px;
    text-align: center;
    span{
        cursor: pointer;
        display: inline-block;
        margin:0 20px;
        padding:5px 0;
        font-size:20px;
    }
    .currs{
        border-bottom: 1px solid #222;
    }
}
.login-group{
    border: 1px solid #DDE0ED;
    border-radius:4px;
    padding:10px 0;
    margin-bottom: 20px;
    
    select,input{
        outline:none;
        border:none;
        padding:0 10px;
        background: transparent;
    }
    .password{
        width: 100%;
    }
}
.login-group-btn{
    cursor: pointer;
    background: #222;
    color: #fff;
    text-align: center;
    border-radius:4px;
    padding:10px 0;
    
}
.login-group-code{
    border: 1px solid #DDE0ED;
    border-radius:4px;
    margin-bottom: 20px;
    display: flex;
    -webkit-box-orient: horizontal;
    position: relative;
    input{
        width:51%;
        padding:10px 10px;
        outline:none;
        border:none;
        background: transparent;
    }
    .button{
        position: absolute;
        top: 0;
        right:0;
        // width: 31%;
        padding:10px;
        text-align: center;
        color: #fff;
        border-radius:4px;
        background: #1F73B7;
        cursor: pointer;
        span{
            color: #fff;
        }
    }
    .glay{
        background: #9a9a9a;
    }
}
@media screen and (min-width: 1200px) {
    .wrap{
        
        .login-group-tab{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group-btn{
            width:500px;
            margin: 0 auto;
        }
        .login-group-code{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        
        .login-group-tab{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group-btn{
            width:500px;
            margin: 0 auto;
        }
        .login-group-code{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        
        .login-group-tab{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group-btn{
            width:500px;
            margin: 0 auto;
        }
        .login-group-code{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    // .wrap{
    // }
}
@media screen and (max-width: 479px) {
    // .wrap{
    // }
}
</style>